import {useState, memo, SyntheticEvent, useEffect, useCallback} from 'react';
import {useStore} from 'effector-react';
import {useLocation} from 'react-router';

import url from '~/utils/url';
import {Page} from '~/utils/getLink';
import useEscortsRoute from '~/utils/useEscortsRoute';
import {EscortSlug} from '~/services/escorts';
import {$currentLocation} from '~/store/currentLocation';
import {$canPostAds} from '~/store/myAds';
import {$account} from '~/store/account';
import {MODAL_HOT_SEARCH} from '~/layout/ModalHotSearch';
import {Icons} from '~/components/icons';
import {showModal} from '~/components/common/Modal';
import {gaLogEvent} from '~/utils/ga';

import FooterItem from './components/FooterItem';
import ChatButton from './components/ChatButton';

import './styles.scss';

const Footer = () => {
  const location = useLocation();
  const [active, setActive] = useState(url.getFooterActive());
  const currentLocation = useStore($currentLocation);
  const account = useStore($account);
  const canPostAds = useStore($canPostAds);

  const isShemaleRoute = useEscortsRoute(EscortSlug.Shemale);

  useEffect(() => {
    setActive(url.getFooterActive());
  }, [location?.pathname]);

  const handleCamsClickCallback = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      gaLogEvent({
        category: 'Menu_down',
        action: 'click_to_cams',
      });

      const goId = isShemaleRoute ? 42 : 41;

      window.open(`https://adultsearch.com/ad?go=${goId}`, '_blank');
    },
    [isShemaleRoute]
  );

  const handleSearchButtonClick = useCallback(() => {
    gaLogEvent({
      category: 'Menu_down',
      action: 'click_to_search',
    });
    showModal(MODAL_HOT_SEARCH);
  }, []);

  const handleHomeButtonClick = useCallback(() => {
    gaLogEvent({
      category: 'Menu_down',
      action: 'click_to_home',
    });
  }, []);

  const handlePostAdButtonClick = useCallback(() => {
    gaLogEvent({
      category: 'Menu_down',
      action: 'click_to_post_ad',
    });
  }, []);

  return (
    <footer className="footer">
      <FooterItem
        icon={Icons.search}
        className="footer__item_search"
        onClick={handleSearchButtonClick}
      >
        Search
      </FooterItem>

      {!account && (
        <FooterItem
          icon={Icons.cams_old}
          className="footer__item_search"
          onClick={handleCamsClickCallback}
        >
          Cams
        </FooterItem>
      )}

      <FooterItem
        isActive={
          currentLocation.url
            ? currentLocation.url === window.location.pathname
            : active === '/all-locations'
        }
        url={currentLocation?.url || '/'}
        icon={Icons.home}
        className="footer__item_home"
        onClick={handleHomeButtonClick}
      >
        Home
      </FooterItem>

      {account && (
        <FooterItem
          isActive={active === 'myposts'}
          url={Page.MyAds}
          icon={Icons.footer_myads}
          className="footer__item_myposts"
        >
          My Ads
        </FooterItem>
      )}

      <FooterItem
        isActive={active === 'adbuild'}
        url={Page.Adbuild}
        icon={Icons.footer_postad}
        className="footer__item_adbuild"
        disabled={!canPostAds}
        onClick={handlePostAdButtonClick}
      >
        Post Ad
      </FooterItem>

      <ChatButton active={active} />

      <FooterItem
        isActive={active === (account ? 'account' : 'signin')}
        url={account ? Page.Account : Page.SignIn}
        icon={Icons.footer_myas}
        className="footer__item_my-as"
      >
        {account ? 'My AS' : 'Sign In'}
      </FooterItem>
    </footer>
  );
};

export default memo(Footer);
