import {createStore, createEffect, createEvent, sample, combine} from 'effector';

import {Category} from '~/store/types';
import {PlaceType, M4MLink} from '~/services/types';
import escorts, {EscortTypeWithQuantity} from '~/services/escorts';
import places from '~/services/places';
import {$city, fetchCityByUrlFx} from '~/store/cities';
import m4mlinks from '~/services/m4mlinks';
import logger from '~/utils/logger';

import {$hasForums} from './forums';

export const resetTypes = createEvent('Reset escorts/places types');

export const loadEscortsTypesFx = createEffect(escorts.getTypes);
export const $escortsTypes = createStore<EscortTypeWithQuantity[]>([])
  .on(loadEscortsTypesFx.doneData, (_, payload) => payload)
  .on(loadEscortsTypesFx.fail, () => [])
  .reset(resetTypes);
export const loadPlacesTypesFx = createEffect(places.getTypes);
export const $placesTypes = createStore<PlaceType[]>([])
  .on(loadPlacesTypesFx.doneData, (_, payload) => payload)
  .on(loadPlacesTypesFx.fail, () => [])
  .reset(resetTypes);
export const loadM4MLinkFx = createEffect(m4mlinks.getLink);
export const $m4mLinks = createStore<M4MLink[]>([])
  .on(loadM4MLinkFx.doneData, (_, payload) => [payload])
  .on(loadM4MLinkFx.fail, () => [])
  .reset(resetTypes);

loadEscortsTypesFx.fail.watch(({error}) => {
  logger.error(error);
});

loadPlacesTypesFx.fail.watch(({error}) => {
  logger.error(error);
});

loadM4MLinkFx.fail.watch(({error}) => {
  logger.error(error);
});

// trigger fetches on $city change!
sample({
  clock: $city,
  filter: (city) => !!city?.id,
  fn: (city) => city?.id,
  target: [resetTypes, loadEscortsTypesFx, loadPlacesTypesFx, loadM4MLinkFx],
});

const forumLink = {
  name: 'Sex Forum',
  namePlural: 'Sex Forums',
  url: 'sex-forum',
};

// "null" slide value means loading process of this type
export const $profileTypes = combine(
  fetchCityByUrlFx.pending,
  $escortsTypes,
  $placesTypes,
  $m4mLinks,
  loadEscortsTypesFx.pending,
  loadPlacesTypesFx.pending,
  loadM4MLinkFx.pending,
  $hasForums,
  (
    cityLoading,
    escortsTypes,
    placesTypes,
    m4mLinks,
    escortsTypesLoading,
    placesTypesLoading,
    m4mLinkLoading,
    hasForums
  ) => {
    const result = [
      ...(cityLoading || escortsTypesLoading ? [null] : escortsTypes),
      ...(cityLoading || m4mLinkLoading ? [null] : m4mLinks),
      ...(cityLoading || placesTypesLoading ? [null] : placesTypes),
    ];

    return (hasForums ? [...result, forumLink] : result) as Array<Category | null>;
  }
);
