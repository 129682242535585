import axios from 'axios';

import {$currentLocation} from '~/store/currentLocation';
import {purgeCache} from '~/utils/cache';
import logger from '~/utils/logger';
import {TEscort} from '~/types/common';

import CacheNames from '../../config/cacheNames';
import axiosInstance, {request, requestPut} from '../api_init';
import {
  EscortType,
  EscortsTypes,
  TopUpsPrice,
  BaseEscortsResponse,
  EscortTypeWithQuantityResponse,
} from './types';
import {EmptyObject} from '../types';

export const getEscortsTypes = () => request<EscortsTypes>('/api/v1/escorts/types');

export const getTopupsPrice = () => request<TopUpsPrice>('/api/v1/pc/escorts/top_ups_price');

export const changeEscortLocation = (escortId: number, fromCityId: number, toCityId: number) =>
  requestPut(`/api/v1/pc/escorts/${escortId}/change_location`, {fromCityId, toCityId});

export const MyEscortsUrl = '/api/v1/pc/escorts';
export const requestMyEscorts = () => request<BaseEscortsResponse>(MyEscortsUrl);

export default {
  isVerified: (escort: TEscort | EmptyObject) => {
    return !!escort?.verified;
  },

  isPendingVerification: (escort: TEscort | EmptyObject) => {
    if (
      typeof escort?.verificationId === 'number' &&
      escort?.verificationStatus === null &&
      !escort?.verifiedStamp
    ) {
      return true;
    }

    return false;
  },

  getById: async (id: number | string) => {
    const res = await axiosInstance.get<TEscort>(`/api/v2/escorts/${id}`);

    return res.data;
  },

  getAdvertising: async (cityId: number | string, escortType: number | string, refresh = false) => {
    if (refresh) {
      await purgeCache(CacheNames.Cities);
    }

    try {
      const res = await axiosInstance.get<BaseEscortsResponse>(
        `/api/v1/cities/${cityId}/escort_types/${escortType}/advertising`
      );

      return res.data.list;
    } catch (error) {
      logger.error(error);

      return [];
    }
  },

  // TODO: rewrite to find
  getTypeById: async (typeId: string) => {
    let type: EscortType | EmptyObject = {};
    const types = await getEscortsTypes();

    if (types) {
      const typeData = types.filter((item) => {
        return item.id === parseInt(typeId, 10);
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [type] = typeData;
    }

    return type;
  },

  getTypes: async (id: number) => {
    const res = await axiosInstance.get<EscortTypeWithQuantityResponse>(
      `/api/v3/getEscortsTypesWithQuantityByLocationId?locationId=${id}`
    );

    return res.data.list;
  },

  getEscortsByLocation: async (locationId: string | number, categoryTypes: string | number) => {
    const params = {
      locationId,
      type: categoryTypes,
      offset: 0,
      limit: 1000,
    };

    const res = await axiosInstance.get<BaseEscortsResponse>(`/api/v3/escorts/${locationId}/list`, {
      params,
    });

    return res.data.list;
  },

  verificationVideoStatus: async (id: string | number) => {
    try {
      const result = await axiosInstance.get('/api/v1/pc/escorts/verification_video_status', {
        params: {id},
      });

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Video processing error');
      }

      throw error;
    }
  },

  videoStatus: async (id: string | number) => {
    try {
      const result = await axiosInstance.get('/api/v1/escorts/video_status', {
        params: {id},
      });

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Video processing error');
      }

      throw error;
    }
  },

  registerEscortVisit: (id: string | number) => {
    let visited;

    try {
      const val = sessionStorage.getItem('visitedEscorts');

      if (val) {
        visited = JSON.parse(val);
      }
    } catch (error) {
      logger.error(error);
    }

    try {
      if (!Array.isArray(visited)) {
        visited = [];
      }

      if (!visited.includes(id)) {
        visited.push(id);

        const {id: locationId} = $currentLocation.getState();

        axiosInstance.get(`/api/v1/escorts/${id}/visit`, {params: {locationId}});

        sessionStorage.setItem('visitedEscorts', JSON.stringify(visited));
      }
    } catch (error) {
      logger.error(error);
    }
  },
};
