import {useStore} from 'effector-react';
import {useNavigate} from 'react-router';
import {useCallback} from 'react';

import {Page} from '~/utils/getLink';
import {$unreadDialogsCount} from '~/store/flashChat';
import {$account} from '~/store/account';
import {isAccountUnverified} from '~/services/account';
import {showModal} from '~/components/common/Modal';
import {Icons} from '~/components/icons';
import {CHAT_UNAVAILABLE_MODAL} from '~/layout/ModalChatUnavailable';
import useIsOnline from '~/utils/useIsOnline';
import {gaLogEvent} from '~/utils/ga';

import FooterItem from './FooterItem';

const ChatButton = ({active}: {active: string}) => {
  const account = useStore($account);
  const unreadDialogsCount = useStore($unreadDialogsCount);
  const navigate = useNavigate();
  const isOnline = useIsOnline();

  const chatDisabled =
    account &&
    (account.agency || !account.hasVerifiedEscortOrEmptyEscorts || isAccountUnverified(account));

  const handleMessageButtonClick = useCallback(() => {
    gaLogEvent({
      category: 'Menu_down',
      action: 'click_to_chat',
    });
    if (!account) {
      navigate(Page.Chat);
      return;
    }

    if (chatDisabled) {
      showModal(CHAT_UNAVAILABLE_MODAL);
      return;
    }

    navigate(Page.Chat);
  }, [account, chatDisabled, navigate]);

  return (
    <FooterItem
      isActive={active === 'chat'}
      onClick={handleMessageButtonClick}
      disabled={!isOnline}
      icon={Icons.footer_messages}
      badge={chatDisabled ? 0 : unreadDialogsCount}
      className="footer__item_messages"
    >
      Messages
    </FooterItem>
  );
};

export default ChatButton;
